import { Button, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { createStyle } from "../../../../utils/tools";
import SectionView from "../../common/SectionView";
import { useCounter, useInterval } from "ahooks";

const { Title } = Typography;

const appScreenshotURLs = [
  "/images/app/home.png",
  "/images/app/home2.png",
  "/images/app/category.png",
  "/images/app/order.png",
  "/images/app/user.png",
];

const buttons = [
  "footer.features.oneStop.title",
  "footer.features.lowPrices.title",
  "footer.features.newCostumer.title",
  "footer.features.contactless.title",
  "footer.features.customerService.title",
];

const description = [
  "footer.features.oneStop.subtitle",
  "footer.features.lowPrices.subtitle",
  "footer.features.newCostumer.subtitle",
  "footer.features.contactless.subtitle",
  "footer.features.customerService.subtitle",
];

const HoverPhone = () => {
  const { t } = useTranslation();

  const [current, {
    inc,
    set,
    reset,
  }] = useCounter(0, { min: 0, max: 4 });

  useInterval(() => {
    current < 4 ? inc() : reset();
  }, 4000);

  const renderButtons = buttons.map((button, index) =>
    <HoverButton
      key={index}
      text={button}
      index={index}
      setActiveIndex={set}
      activeIndex={current}
    />
  );

  return (<SectionView
    title={t("app.luniuApp")}
    subtitle={t("app.availablePlatform")}
    backgroundColor="white"
  >
    <div style={styles.buttonsContainer}>
      {renderButtons}
    </div>

    <img style={styles.appScreenshot} src={appScreenshotURLs[current]} />
    <Title level={4} style={{ textAlign: "center" }}>
      {t(description[current])}
    </Title>
  </SectionView>);
};

type HoverButtonProps = {
  text: string;
  index: number;
  activeIndex: number;
  setActiveIndex: (value: number | ((c: number) => number)) => void;
};

const HoverButton = (
  { text, index, setActiveIndex, activeIndex }: HoverButtonProps,
) => {
  const { t } = useTranslation();
  return <Button
    type={index === activeIndex ? "primary" : "default"}
    onClick={() => {
      setActiveIndex(index);
    }}
    style={styles.button}
  >
    {t(text)}
  </Button>;
};

const styles = createStyle({
  appScreenshot: {
    width: 250,
    margin: "0 auto",
  },
  buttonsContainer: {
    margin: "0px auto",
    display: "flex",
    width: " 100%",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  button: {
    margin: "5px 10px",
  },
});

export default HoverPhone;
