import React from 'react';
import SectionView from '../../common/SectionView';
import { Row, Col, Typography } from 'antd';
import { createStyle } from '../../../../utils/tools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faAndroid } from '@fortawesome/free-brands-svg-icons';
import { tintColorLight } from '../../../constants/colors';
import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;

const DownloadApp = () => {
  const { t } = useTranslation();

  return (
    <SectionView
      backgroundColor="#dcf5de"
      backgroundImg="/images/app/section-bg.png"
      useCover
    >
      <Row justify="center">
        <Col span={24} md={10}>
          <div style={styles.infoContainer}>
            <img style={styles.appIcon} src="/images/app/appicon.png" />
            <Title level={3} style={{ color: 'white' }}>
              {t('app.appName')}
            </Title>
            <Text style={{ color: 'white' }}>{t('app.subtitle')}</Text>
            <Text
              style={{
                color: 'white',
                marginTop: 20,
                marginBottom: 10,
                fontSize: 18,
              }}
            >
              {t('app.clickToDownload')}
            </Text>
            <div style={styles.downloadBtnContainer}>
              <a
                target="blank"
                href="https://apps.apple.com/app/id1525586546"
                style={styles.downloadBtn}
              >
                <FontAwesomeIcon
                  icon={faApple}
                  size="2x"
                  style={{ marginRight: 10 }}
                />
                <p style={{ padding: 0, margin: 0, fontWeight: 'bold' }}>
                  App Store
                </p>
              </a>

              <a
                target="blank"
                href="https://tools.luniumall.com/download"
                style={styles.downloadBtn}
              >
                <FontAwesomeIcon
                  icon={faAndroid}
                  size="2x"
                  style={{ marginRight: 10 }}
                />
                <p style={{ padding: 0, margin: 0, fontWeight: 'bold' }}>
                  Android
                </p>
              </a>
            </div>
          </div>
        </Col>
        <Col span={24} md={12}>
          <img style={styles.phoneInHand} src="/images/app/luniuHand.png" />
        </Col>
      </Row>
    </SectionView>
  );
};

const styles = createStyle({
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
  },
  appIcon: {
    borderRadius: 20,
    width: 100,
    marginBottom: 10,
  },
  downloadBtnContainer: {
    display: 'flex',
  },
  downloadBtn: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 5,
    borderColor: tintColorLight,
    backgroundColor: 'white',
    marginLeft: 10,
  },
  phoneInHand: {
    maxWidth: '100%',
    position: 'relative',
    bottom: -32,
  },
});

export default DownloadApp;
